<template>
	<div>
	
		<div class="content content_top_margin">
			<div class="content_inner  ">
				<div class="full_width ">
					<div class="full_width_inner">

						<div class="vc_row wpb_row section vc_row-fluid  vc_custom_1636151250857 grid_section"
							style=' text-align:left;'>
							<div class=" section_inner clearfix">
								<div class='section_inner_margin clearfix'>
									<div class="wpb_column vc_column_container vc_col-sm-12">
										<div class="vc_column-inner">
											<div class="wpb_wrapper">
												<div class="vc_row wpb_row section vc_row-fluid vc_inner  single_top_row"
													style=' text-align:left;'>
													<div class=" full_section_inner clearfix">
														<div class="wpb_column vc_column_container vc_col-sm-8">
															<div class="vc_column-inner">
																<div class="wpb_wrapper"></div>
															</div>
														</div>
														<div class="wpb_column vc_column_container vc_col-sm-4">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<p>
																				<router-link :to="{ name: 'sit-and-stand-a45' }">
																						<em>Up
																						Next: </em> Sit and Stand <img
																						class="vc_single_image-img attachment-full"
																						src="../../assets/images/airbnb/Airbnb-Arrows-2.png"
																						alt="" width="64"
																						height="37" />
																				</router-link>
																			</p>

																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div class="vc_empty_space" style="height: 52px"><span
														class="vc_empty_space_inner">
														<span class="empty_space_image"></span>
													</span></div>

												<div class="vc_row wpb_row section vc_row-fluid vc_inner  vc_custom_1636691391154"
													style=' text-align:left;'>
													<div class=" full_section_inner clearfix">
														<div class="wpb_column vc_column_container vc_col-sm-4">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<h2>Pomodoro Technique</h2>
																			<h3>How it works</h3>
																			<p>The Pomodoro Technique is a time
																				management strategy developed by
																				Francesco Cirillo in the late 1980s. He
																				named the method after his tomato-shaped
																				kitchen timer that he used when he was a
																				university student.</p>
																			<p>The method is very straightforward and
																				allows you to organize your work into 60
																				– 90 minute chunks with focused actions
																				and breaks in between sessions.</p>

																		</div>
																	</div>
																	<div class="vc_empty_space" style="height: 32px">
																		<span class="vc_empty_space_inner">
																			<span class="empty_space_image"></span>
																		</span></div>

																</div>
															</div>
														</div>
														<div class="wpb_column vc_column_container vc_col-sm-8">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_right   rounded">
																		<div class="wpb_wrapper">

																			<div
																				class="vc_single_image-wrapper   vc_box_border_grey">
																				<img width="700" height="340"
																					src="../../assets/images/airbnb/Airbnb-Productivity-Pomodoro-1.jpg"
																					class="vc_single_image-img attachment-full"
																					alt="" loading="lazy"
																					title="Airbnb---Productivity---Pomodoro" />
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div class="vc_empty_space" style="height: 52px"><span
														class="vc_empty_space_inner">
														<span class="empty_space_image"></span>
													</span></div>

												<div class="vc_row wpb_row section vc_row-fluid vc_inner  two_cols"
													style=' text-align:left;'>
													<div class=" full_section_inner clearfix">
														<div
															class="wpb_column vc_column_container vc_col-sm-4 vc_col-has-fill">
															<div class="vc_column-inner rounded_border-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">

																			<div
																				class="vc_single_image-wrapper   vc_box_border_grey">
																				<img width="50" height="50"
																					src="../../assets/images/airbnb/Airbnb-Productivity-Pomodoro-How-it-works-1.png"
																					class="vc_single_image-img attachment-full"
																					alt="" loading="lazy"
																					title="Airbnb---Productivity---Pomodoro---How-it-works-1" />
																			</div>
																		</div>
																	</div>
																	<div class="vc_empty_space" style="height: 32px">
																		<span class="vc_empty_space_inner">
																			<span class="empty_space_image"></span>
																		</span></div>


																	<div
																		class="wpb_text_column wpb_content_element  no-margin vc_custom_1637263478245">
																		<div class="wpb_wrapper">
																			<h4>Set your timer</h4>
																			<p>&nbsp;</p>
																			<p>First, decide which task you want to
																				focus on. This is a deep dive session,
																				and can be a full project or part of a
																				project.</p>
																			<p>&nbsp;</p>
																			<p>Set your time for 25 minutes and, for the
																				next 25 minutes, focus only on that
																				task.</p>

																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div
															class="wpb_column vc_column_container vc_col-sm-4 vc_col-has-fill">
															<div class="vc_column-inner rounded_border-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">

																			<div
																				class="vc_single_image-wrapper   vc_box_border_grey">
																				<img width="50" height="50"
																					src="../../assets/images/airbnb/Airbnb-Productivity-Pomodoro-How-it-works-2.png"
																					class="vc_single_image-img attachment-full"
																					alt="" loading="lazy"
																					title="Airbnb---Productivity---Pomodoro---How-it-works-2" />
																			</div>
																		</div>
																	</div>
																	<div class="vc_empty_space" style="height: 32px">
																		<span class="vc_empty_space_inner">
																			<span class="empty_space_image"></span>
																		</span></div>


																	<div
																		class="wpb_text_column wpb_content_element  no-margin vc_custom_1637263529151">
																		<div class="wpb_wrapper">
																			<h4>Tune out distractions</h4>
																			<p>&nbsp;</p>
																			<p>In preparation for the deep dive
																				sessions, tune out ALL distractions.
																				That includes texts, email
																				notifications, turning off your ringer
																				and even putting an away message on,
																				letting your team know that you are in
																				deep dive mode.</p>

																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div
															class="wpb_column vc_column_container vc_col-sm-4 vc_col-has-fill">
															<div class="vc_column-inner rounded_border-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">

																			<div
																				class="vc_single_image-wrapper   vc_box_border_grey">
																				<img width="50" height="50"
																					src="../../assets/images/airbnb/Airbnb-Productivity-Pomodoro-How-it-works-3.png"
																					class="vc_single_image-img attachment-full"
																					alt="" loading="lazy"
																					title="Airbnb---Productivity---Pomodoro---How-it-works-3" />
																			</div>
																		</div>
																	</div>
																	<div class="vc_empty_space" style="height: 32px">
																		<span class="vc_empty_space_inner">
																			<span class="empty_space_image"></span>
																		</span></div>


																	<div
																		class="wpb_text_column wpb_content_element  no-margin vc_custom_1637263568905">
																		<div class="wpb_wrapper">
																			<h4>Break time!</h4>
																			<p>&nbsp;</p>
																			<p>After the first 25 minute session, take a
																				5 minute break. Then, do another 25
																				minute session, followed by another 5
																				minute break. Repeat once more and then
																				take a more substantial break.</p>

																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
							<div class="vc_row wpb_row section vc_row-fluid  grid_section" style=" text-align:left;">
							<div class=" section_inner clearfix">
								<div class="section_inner_margin clearfix">
									<div class="wpb_column vc_column_container vc_col-sm-12">
										<div class="vc_column-inner">
											<div class="wpb_wrapper">
												<div class="vc_empty_space" style="height: 32px"><span
														class="vc_empty_space_inner">
														<span class="empty_space_image"></span>
													</span></div>


												<div class="wpb_text_column wpb_content_element ">
													<div class="wpb_wrapper">
														<h2>Discover more productivity tips</h2>

													</div>
												</div>
												<div class="vc_empty_space" style="height: 32px"><span
														class="vc_empty_space_inner">
														<span class="empty_space_image"></span>
													</span></div>

												<div class="vc_row wpb_row section vc_row-fluid vc_inner  continue_block"
													style=" text-align:left;">
													<div class=" full_section_inner clearfix">
														<div class="wpb_column vc_column_container vc_col-sm-4">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																	<div class="wpb_wrapper">
																		<router-link :to="{ name: 'increase-your-energy-a45' }">
																			<div
																					class="vc_single_image-wrapper   vc_box_border_grey">
																					<img width="750" height="537"
																						src="../../assets/images/airbnb/Airbnb-Working-From-Office-Everyday-stretches.jpg"
																						class="vc_single_image-img attachment-full"
																						alt="" loading="lazy"
																						title="Airbnb-Working-From-Office---Everyday-stretches">
																			</div>
																			</router-link>
																		</div>
																	</div>

																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'increase-your-energy-a45' }">
																			<h4>Increase your energy</h4>
																			<p>How to manage your energy rather than
																				your time.</p>
																			</router-link>

																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div class="wpb_column vc_column_container vc_col-sm-4">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'the-pomodoro-technique-a45' }">
																			
																				<div
																					class="vc_single_image-wrapper   vc_box_border_grey">
																					<img width="750" height="364"
																						src="../../assets/images/airbnb/Airbnb-Productivity-Pomodoro.jpg"
																						class="vc_single_image-img attachment-full"
																						alt="" loading="lazy"
																						title="Airbnb---Productivity---Pomodoro"
																						>
																				</div>
																			</router-link>
																		</div>
																	</div>

																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'the-pomodoro-technique-a45' }">
																			<h4>Pomodoro Technique</h4>
																			<p>Manage your time with the Pomodoro
																				Technique.</p>
																			</router-link>

																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div class="wpb_column vc_column_container vc_col-sm-4">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'sensory-overload-a45' }">
																				<div
																					class="vc_single_image-wrapper   vc_box_border_grey">
																					<img width="750" height="500"
																						src="../../assets/images/airbnb/Airbnb-Productivity-Sensory-Overload.jpg"
																						class="vc_single_image-img attachment-full"
																						alt="" loading="lazy"
																						title="Airbnb---Productivity---Sensory-Overload"
																						>
																				</div>
																			</router-link>
																		</div>
																	</div>

																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'sensory-overload-a45' }">
																			<h4>Sensory overload</h4>
																			<p>Check out these hacks to avoid headaches,
																				eye and ear fatigue and more.</p>
																			</router-link>

																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div class="wpb_column vc_column_container vc_col-sm-4">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'productivity-hacks-a45' }">
																				<div
																					class="vc_single_image-wrapper   vc_box_border_grey">
																					<img width="2560" height="905"
																						src="../../assets/images/airbnb/Replacement-for-Productivity-Hacks-Structure-Your-Day2-scaled.jpg"
																						class="vc_single_image-img attachment-full"
																						alt="" loading="lazy"
																						title="Replacement for Productivity Hacks - Structure Your Day2"
																						>
																				</div>
																			</router-link>
																		</div>
																	</div>

																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'productivity-hacks-a45' }">
																			<h4>Productivity hacks</h4>
																			<p>Productivity hacks to help you crush your
																				work day.</p>
																			</router-link>

																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div class="wpb_column vc_column_container vc_col-sm-4">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'time-management-a45' }">
																				<div
																					class="vc_single_image-wrapper   vc_box_border_grey">
																					<img width="750" height="501"
																						src="../../assets/images/airbnb/Airbnb-Productivity-Time-Management.jpg"
																						class="vc_single_image-img attachment-full"
																						alt="" loading="lazy"
																						title="Airbnb---Productivity--Time-Management"
																						>
																				</div>
																			</router-link>
																		</div>
																	</div>

																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'time-management-a45' }">
																			<h4>Time management</h4>
																			<p>Manage your time between work and
																				downtime.</p>
																			</router-link>

																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div class="wpb_column vc_column_container vc_col-sm-4">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'enhanced-focus-a45' }">
																				<div
																					class="vc_single_image-wrapper   vc_box_border_grey">
																					<img width="750" height="482"
																						src="../../assets/images/airbnb/Airbnb-Productivity-Enhanced-Focus.jpg"
																						class="vc_single_image-img attachment-full"
																						alt="" loading="lazy"
																						title="Airbnb---Productivity---Enhanced-Focus"
																						>
																				</div>
																			</router-link>
																		</div>
																	</div>

																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'enhanced-focus-a45' }">
																			<h4>Enhanced focus</h4>
																			<p>Get focused and get productive with these
																				focus enhancing tips.</p>
																			</router-link>

																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div class="vc_empty_space" style="height: 32px"><span
														class="vc_empty_space_inner">
														<span class="empty_space_image"></span>
													</span></div>

											</div>
										</div>
									</div>
								</div>
							</div>
						</div>


					</div>
				</div>
			

			</div>
		</div>
	</div>
</template>
<script>
	//import carousel from "vue-owl-carousel"
	export default {
		name: 'the-pomodoro-technique-a45',
		mounted(){
			document.title = "PBErgo - "+this.$router.currentRoute.meta.page_title;
		},
		methods: {
			hideModal() {
				// this.$emit('hide');
				document.getElementById("closed").src += "";
			},
		}
	}
</script>